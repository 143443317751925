import React from "react";

export default function PageFeaturesServiceCard({ title, details, icon }) {
	return (
		<>
			<div className="w-full px-4 md:w-1/2 lg:w-1/2 xl:w-5/12">
				<div className="mb-8 flex md:block lg:mb-[70px] lg:flex 2xl:px-4">
					<div className="relative mb-8 mr-6 flex h-[86px] w-full max-w-[92px] items-center justify-center 2xl:mr-12">
			          <span className="absolute flex h-full w-full items-center justify-center">
			            <svg
				            width="90"
				            height="80"
				            viewBox="0 0 90 80"
				            fill="none"
				            xmlns="http://www.w3.org/2000/svg"
			            >
			              <path
				              d="M77.3075 75.5919C55.5426 89.4391 4.12998 68.3894 0.253208 43.9936C-3.71342 19.5606 39.8558 -8.21873 65.6266 2.28194C91.2683 12.8307 99.1116 61.6595 77.3075 75.5919Z"
				              fill="#3056D3"
				              fillOpacity="0.06"
			              />
			            </svg>
			          </span>
						<span>{ icon }</span>
					</div>
					<div>
						<h4 className="mb-4 text-[22px] font-bold leading-[32px] text-dark dark:text-white">
							{ title }
						</h4>
						<p className="text-base text-body-color dark:text-dark-6">
							{ details }
						</p>
					</div>
				</div>
			</div>
		</>
	)
}
