import './assets/css/tailwind.css'
import "./index.css";

import { createRoot } from "react-dom/client";
import App from "./App";

const root = createRoot(document.getElementById("root"));
root.render(<App />);

// export {
//   About,
//   Alerts,
//   Badge,
//   Blog,
//   Brand,
//   Breadcrumb,
//   BreadcrumbLink,
//   Button,
//   ButtonGroup,
//   Checkbox,
//   Cookies1,
//   Cookies2,
//   Cookies4,
//   Cta,
//   Dropdown,
//   Error,
//   Footer,
//   FormElement,
//   Forms,
//   Hero,
//   List,
//   Modal,
//   Navbar,
//   Pagination,
//   Portfolio,
//   Pricing,
//   Progress,
//   Service,
//   Switch,
//   Tab,
//   Table,
//   TableBody,
//   TableHead,
//   Team,
//   Tooltip,
//   Video,
// };
