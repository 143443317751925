import React, {useState} from 'react';
import  logo from './../../assets/images/logo.png'
import  env from './../../assets/images/envelope-regular.svg'

export function PageHeroNavbar() {
	const [open, setOpen] = useState(false);
	
	return (
		<>
			{/* <!-- ====== PageHeroNavbar Section Start --> */ }
			<header className="absolute left-0 top-0 z-50 w-full bg-white dark:bg-dark lg:overflow-hidden">
				<div className="container mx-auto">
					<div className="relative -mx-4 flex items-center justify-between">
						<div className="relative z-10 w-60 max-w-full px-4 py-3">
							<a href="#" className="block w-full">
								<img
									src={logo}
									alt="logo"
									className="w-full"
                  style={{height:50, width:50, display:'inline-block'}}
								/>
							</a>
						</div>
						<div className="flex w-full items-center justify-between px-4">
							<div>
								<button
									onClick={ () => setOpen(!open) }
									className={ ` ${
										open && 'navbarTogglerActive'
									} absolute right-4 top-1/2 block -translate-y-1/2 rounded-lg px-3 py-[6px] ring-primary focus:ring-2 lg:hidden` }
								>
									<span className="relative my-[6px] block h-[2px] w-[30px] bg-body-color dark:bg-white"></span>
									<span className="relative my-[6px] block h-[2px] w-[30px] bg-body-color dark:bg-white"></span>
									<span className="relative my-[6px] block h-[2px] w-[30px] bg-body-color dark:bg-white"></span>
								</button>
								<nav
									className={ `absolute right-4 top-full z-20 w-full max-w-[250px] rounded-lg bg-white px-6 py-5 shadow dark:bg-dark-2 lg:static lg:block lg:w-full lg:max-w-full lg:bg-transparent lg:py-0 lg:shadow-none lg:dark:bg-transparent ${
										!open && 'hidden'
									} ` }
								>
									<ul className="block lg:flex">
										{/* <ListItem NavLink="#">Talent Habitat</ListItem> */}
										{/* <ListItem NavLink="/#">Features</ListItem> */}
										{/* <ListItem NavLink="/#">Contact</ListItem> */}
									</ul>
								</nav>
							</div>
              <div className="hidden justify-end pr-16 sm:flex lg:pr-0">
                <div className="flex items-center">
                  <div className="flex h-11 min-w-[44px] items-center justify-center rounded-full">
                    <img src={ env } alt="logo" style={ { width: 30, height: 30, display: 'inline-block' } } />
                  </div>
                  <div className="ml-4 w-full whitespace-nowrap">
                    
                    <h6 className="text-base font-semibold text-dark dark:text-white">
                      info@talenthabitat.com
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <!-- ====== PageHeroNavbar Section End -->    */ }
    </>
  );
}


const ListItem = ({ children, NavLink }) =>
{
  return (
    <li>
      <a
        href={ NavLink }
        className="flex border-transparent py-2 text-base font-medium text-dark hover:border-primary hover:text-primary dark:text-white lg:ml-10 lg:inline-flex lg:border-t-2 lg:py-7"
      >
        { children }
      </a>
    </li>
  );
};
