import PageBenefitsCTA from './PageComponents/PageBenefitsCTA/PageBenefitsCTA';
import PageBrands from './PageComponents/PageBrands/PageBrands';
import PageContact from './PageComponents/PageContact/PageContact';
import PageFeatures from './PageComponents/PageFeatures/PageFeatures';
import PageHero from './PageComponents/PageHero/PageHero';
import PageRecentWork from './PageComponents/PageRecentWork/PageRecentWork';


function App() {
  return (
    <>
	    <PageHero />
	    <PageFeatures />
	    <PageContact />
    </>
  );
}

export default App;
