import React from "react";
import logo_spca from "./../../assets/images/logo.svg";
import logo_rmhc from "./../../assets/images/logo_rmhc.png";
import logo_cancer from "./../../assets/images/logo_cs.png";
import logo_snc from "./../../assets/images/logo_snc.png";
import logo_oxfam from "./../../assets/images/oxfam.avif";
import logo_fh from "./../../assets/images/fh.webp";
import wellelder from "./../../assets/images/wellelder.png";
import logo_gp from "./../../assets/images/gp-logo.svg";


const brandsData = [
	{
		imageSrc:logo_spca,
		lightImageSrc:logo_spca,
		altText: "SPCA",
		link: "#",
	},
	{
		imageSrc:logo_rmhc,
		lightImageSrc:logo_rmhc,
		altText: "RMHC",
		link: "#",
	},
	{
		imageSrc:logo_cancer,
		lightImageSrc:logo_cancer,
		altText: "Cancer Society",
		link: "#",
	},
	{
		imageSrc:logo_snc,
		lightImageSrc:logo_snc,
		altText: "SNC",
		link: "#",
	},
	{
		imageSrc:logo_oxfam,
		lightImageSrc:logo_oxfam,
		altText: "Oxfam",
		link: "#",
	},
	{
		imageSrc:logo_fh,
		lightImageSrc:logo_fh,
		altText: "FH",
		link: "#",
	},
	{
		imageSrc:logo_gp,
		lightImageSrc:logo_gp,
		altText: "GP",
		link: "#",
	},
	{
		imageSrc:wellelder,
		lightImageSrc:wellelder,
		altText: "WellElder",
		link: "#",
	},
];

export default function PageBrands() {
	return (
		<section className="bg-white py-20 lg:py-[120px] bg-[#F1F4FF] dark:bg-dark">
			<div className="container mx-auto">
				<div className="-mx-4 flex flex-wrap">
					<div className="w-full px-4">
						<div className="flex flex-wrap items-center justify-center">
							{brandsData.map((brand, i) => (
								<SingleImage key={i} brand={brand} />
							))}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

const SingleImage = ({ brand }) => {
	const { link, imageSrc, lightImageSrc, altText } = brand;
	
	return (
		<>
			<a
				className="flex h-20 w-[150px] items-center justify-center rounded px-4 hover:shadow-[0px_7px_25px_0px_#E3E6F0] lg:w-[200px] 2xl:w-[180px] dark:hover:shadow-[0px_7px_25px_0px_rgba(0,0,0,.5)]"
			>
				<img src={imageSrc} alt={altText} className="h-10 w-full dark:hidden" style={{width:'auto', maxWidth:'auto'}} />
				<img
					src={lightImageSrc}
					alt={altText}
					className="hidden h-10 w-full dark:block"
				/>
			</a>
		</>
	);
};
